<script>
  import { mapActions } from "vuex";
  import "@vueform/multiselect/themes/default.css";

  import {
    required,
    helpers
  } from "@vuelidate/validators";
  import useVuelidate from "@vuelidate/core";


  import appConfig from "../../../../app.config";
  import Swal from "sweetalert2";
  import axios from 'axios';
  import animationData from "@/components/widgets/msoeawqm.json";
  import animationData1 from "@/components/widgets/gsqxdxog.json";
  //import Lottie from "@/components/widgets/lottie.vue";
  import moment from 'moment'
  import serverConfig from '@/helpers/config';
  
  
  export default {
    name:'TablePlan',
    setup() {
      moment.locale('it')
    
    
      let tk = 'Bearer '+localStorage.getItem('tk')


      return { tk, v$: useVuelidate()};
    },
    page: {
      title: "Lista pratiche in lavorazione",
      meta: [{
        name: "description",
        content: appConfig.description
      }],
    },
    data() {
      return {
        UrlServer: serverConfig.EP,
        datastore: this.$store.state,
        title: "Lista pratiche in lavorazione",
        items: [{
            text: "Elenco sopralluoghi",
            href: "/plan/list-view",
          },
          {
            text: "Lista",
            active: true,
          },
        ],
        date3: null,
        rangeDateconfig: {
          wrap: true, // set wrap to true only when using 'input-group'
          altFormat: "M j, Y",
          altInput: true,
          dateFormat: "d M, Y",
          mode: "range",
        },
        config: {
          wrap: true, // set wrap to true only when using 'input-group'
          altFormat: "M j, Y",
          altInput: true,
          dateFormat: "d M, Y",
        },
        timeConfig: {
          enableTime: false,
          dateFormat: "d M, Y",
        },
        filterdate: null,
        filterdate1: null,
        filtervalue: 'All',
        filtervalue1: 'All',
        filtersearchQuery1:null,
        date: null,
        submitted: false,
        regError: null,
        tryingToRegister: false,
        isRegisterError: false,
        registerSuccess: false,
        item:{
          source:'',
          customerCategory:'',
          systemCategory:'',
          systemSubCategory:'',
          fiscalBenefit:'',
        },
        optionsFiscalBenefit:[],
        optionsFSPrivate:[
          { value: '', label: 'Seleziona beneficio fiscale' },
          { value: 'conto_termico', label: 'Conto termico' },
          { value: 'detrazione_fiscale', label: 'Detrazione fiscale' },
        ],
        optionsFSBusiness:[
          { value: '', label: 'Seleziona beneficio fiscale' },
          { value: 'credito_imposta', label: "Credito d'imposta" },
          { value: 'sconto_in_fattura', label: 'Sconto in fattura' },
        ],
        optionsFSPA:[
          { value: 'non_previsto', label: 'Non previsto' },
        ],
        allTask: [
        ],
        searchQuery: null,
        page: 1,
        perPage: 50,
        sortRes:'asc',
        value: 'All',
        pages: [],
        projectsList: [
        ],
        paginated:{},
        select:'all',
        search:'',
        defaultOptions: {
          animationData: animationData
        },
        defaultOptions1: {
          animationData: animationData1
        },
        form:{
          start:'',
          end:'',
          status:'init',
          select:'',
        },
        n_results:0,
        minDate:moment().format('YYYY-MM-DD hh:mm'),
        maxDate:moment().format('YYYY') + '-12-31 hh:mm',
        minDateEnd:moment().format('YYYY-MM-DD hh:mm'),
        maxDateEnd:moment().format('YYYY') + '-12-31 hh:mm',
      };
    },
    components: {
      //lottie: Lottie,
  
     
    },
    validations: {
      item: {
        source: {
          required: helpers.withMessage("Campo obbligatorio", required),
        },
        customerCategory: {
          required: helpers.withMessage("Campo obbligatorio", required),
        },
        systemCategory: {
          required: helpers.withMessage("Campo obbligatorio", required),
        },
        systemSubCategory: {
          required: helpers.withMessage("Campo obbligatorio", required),
        },
        fiscalBenefit: {
          required: helpers.withMessage("Campo obbligatorio", required),
        },
      },
    },
    computed: {
      
    },
    watch: {
      allTask() {
        this.setPages();
      },
    },
    created() {
      this.setPages();
    },
    filters: {
      trimWords(value) {
        return value.split(" ").splice(0, 20).join(" ") + "...";
      },
    },
    beforeMount() {
      this.getList()
    },

    methods: {
      convertDate(date){
        if (date) {
          moment.locale('it')
          return moment.unix(date).format('DD/MM/YYYY')
        }
      },
      updateDate(type){
        if (type=='start'){
          this.minDateEnd = this.form.start
          this.form.end = this.form.start
        }
        if (type=='end'){
          this.minDateEnd = this.form.start
        }
      },
      ...mapActions('layout', ['changeLayoutType', ]),
    
        axiosInterceptor(){
        axios.interceptors.response.use(
          (response) => {
            return response;
          },
          (error) => {
              if (error.response && error.response.status === 401) {
                Swal.fire({
                  title:"Accesso non autorizzato",
                  text:"Mi spiace, ma l'accesso a questa risorsa non è autorizzato, probabilmente a causa della sessione scaduta o per mancanza dei requisiti",
                  icon:"warning",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })

                  this.$router.push('/login')
                  if(localStorage.getItem('tk')){
                    localStorage.removeItem('tk')
                  }
                  return Promise.reject('Unauthorized access');
              } else if (error.response && error.response.status  === 404) {
                  Swal.fire({
                    title:"Nessun risultato trovato",
                    text:"Mi spiace, nessun risultato trovato",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                  return Promise.reject('The server cannot find the requested resource');
              }  else if (error.response && error.response.status  === 412) {
                Swal.fire({
                  title:"Credenziali mancanti",
                  text:"Inserisci delle credenziali valide, per favore.",
                  icon:"warning",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                return Promise.reject('The server cannot find the requested resource');
            } else if (error.response && error.response.status  === 423) {
                  Swal.fire({
                    title:"Risorsa non accessibile",
                    text:"Il contenuto al quale stai tentando di accedere non è accessibile.",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                  this.$router.push('/login')
                  if(localStorage.getItem('tk')){
                    localStorage.removeItem('tk')
                  }            
                  return Promise.reject('The server cannot find the requested resource');
              }
              else if (error.response && error.response.status  === 500) {
                Swal.fire({
                  title:"Errore Server",
                  text:"Mi spiace, ma riscontriamo degli errori sul server.",
                  icon:"error",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                  return Promise.reject('Internal Server Error');
              }
              else if (error.response && error.response.status  === 502) {
                  Swal.fire({
                    title:"Errore Server",
                    text:"Mi spiace, ma riscontriamo degli errori sul server.",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                  return Promise.reject('Bad Gateway');
              }
              else if (error.response && error.response.status  === 503) {
                Swal.fire({
                  title:"Servizio non disponibile",
                  text:"Mi spiace, il servizio richiesto è momentaneamente indisponibile.",
                  icon:"error",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                  return Promise.reject('Service Unavailable');
              } else {
                console.log(error.response.status )
                Swal.fire({
                  title:"Errore imprevisto",
                  text:"Ops! si è verificato un problema...riprova, per favore.",
                  icon:"error",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                
              }

          }
        );
        
      },
      getList(){
       this.axiosInterceptor()
       axios.get(`${this.UrlServer}projects/per-page/all`, 
        { 
          params: { 
            perPage: this.perPage,
            page: this.page,
            sortRes: this.sortRes,
            select: this.select,
            search: this.search,
          }, 
          headers: { 'authorization':this.tk}
        }
       ).then(response=>{
        this.projectsList = response.data.results.data;
        this.paginated = response.data.results
        this.n_results = response.data.n_results
      })
      },
      SearchData(){
        if (this.form.start!='' && this.form.end!=''){
          this.axiosInterceptor()
          axios.get(`${this.UrlServer}projects/filter/all`, 
            { 
              params: { 
                perPage: this.perPage,
                page: this.page,
                sortRes: this.sortRes,
                select: this.select,
                search: this.search,
                start:moment(this.form.start).unix(),
                end:moment(this.form.end).unix(),
                status:this.form.status,
              }, 
              headers: { 'authorization':this.tk}
            }
          ).then(response=>{
            this.projectsList = response.data.results.data;
            this.paginated = response.data.results
            this.n_results = response.data.n_results
            })
        }
      },
      filterOptions(val){
        switch(val){
          case "azienda":
            this.optionsFiscalBenefit = this.optionsFSBusiness
            break;
          case "privato":
            this.optionsFiscalBenefit = this.optionsFSPrivate
            break;
          case "pubblico":
            this.optionsFiscalBenefit = this.optionsFSPA
            break;
        }        
      },
      editdata(data) {
        this.$router.push('/projects/detail/plan/'+data)
      },
      addnew() {
        document.getElementById("addform").reset();
        document.getElementById('createModalLabel').innerHTML = this.$t('t-new-project');
        document.getElementById('add-btn').style.display = 'block';
       
      },
      initItem(){

        this.submitted = true;
        
        this.v$.$touch();

        this.item.name = 'Nuovo progetto avviato'
        this.item.dateString = moment().format('YYYY-MM-DD')
        this.item.dateTmp = moment().unix()
        this.item.year = moment().format('YYYY')
        this.item.start = moment().valueOf()
        this.item.start_tmp = moment().unix()
        this.item.createdBy = this.datastore.auth.currentUser
        this.axiosInterceptor()
        axios.post(`${this.UrlServer}projects/register`, this.item, {headers: { authorization:this.tk}} )
          .then((response)=>{
            let self = this
            document.getElementById("closemodal").click();
            Swal.fire({  
              title: this.$t("t-msg-project-init") , 
              text: this.$t("t-msg-project-init-content"), 
              icon:"success",              
              showCancelButton:false,
              showConfirmButton:false,
              timer:3000
              }).then(()=>{
                 self.$router.push('/worksite/detail/'+response.data)
              });
            
          })

      },
      setPages() {
        let numberOfPages = Math.ceil(this.allTask.length / this.perPage);
        this.pages = [];
        for (let index = 1; index <= numberOfPages; index++) {
          this.pages.push(index);
        }
      },
      paginate(customerList) {
        let page = this.page;
        let perPage = this.perPage;
        let from = page * perPage - perPage;
        let to = page * perPage;
        return customerList.slice(from, to);
      },
      paginateAct(pg){
        this.page = pg
        this.getList()
      },
      splitStr(str,car){
        if (str){
          if(str.includes(car)==true) {
            let val =  str.split(car)
            return `${val[0]} ${val[1]}`
          } else{
            return str
          }
        } else {
          return ''
        }
    }
    },
    mounted() {

    }
  };
</script>

<template>
 
    
<!-- Modulo tabella elenco progetti/cantieri -->    
    <div class="row">
      <div class="col-lg-12">
        <div class="card" id="tasksList">
          <div class="card-header border-0">
            <div class="d-flex align-items-center">
              <h5 class="card-title mb-0 flex-grow-1">{{ $t("t-all-projects") }}</h5>
              <div class="flex-shrink-0">
                
              </div>
            </div>
          </div>
      
          <div class="card-body border border-dashed border-end-0 border-start-0">
            <div class="mb-50">
              <div class="row rowSearchFormBGce g-4 mb-50">
                
                  <div class="col-lg-5 col-md-12">
                    <div class="">
                      <div class="search-box ms-2">
                        <input type="text" class="form-control"  :placeholder="$t('t-search-for') "  v-model="search" @input="getList()" />
                        <i class="ri-search-line search-icon"></i>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-2 col-md-12">
                    <div class="">
                      <input type="date"  :placeholder="$t('t-from')"   class="form-control" v-model="form.start" :min="minDate" :max="maxDate" @change="updateDate('start')">
                    </div>
                  </div>
                  <div class="col-lg-2 col-md-12">
                    <div class="">
                      <input type="date"  :placeholder="$t('t-to')"   class="form-control" v-model="form.end" :min="minDateEnd" :max="maxDateEnd"  @change="updateDate('end')" >
                    </div>
                  </div>
                  <div class="col-lg-2 col-md-12">
                    <select class="form-select" v-model="form.status">
                       <option value="all">Tutti</option>
                       <option value="init">Non assegnati</option>
                       <option value="assigned">Assegnati</option>
                       <option value="pending">Lavorazione in corso</option>
                       <option value="blocked">Bloccati</option>
                       <option value="canceled">Annullati</option>
                       <option value="completed">Completati</option>
                    </select>

                  </div>
                  <div class="col-lg-1 col-md-12">
                    <button type="button" class="btn btn-primary w-100" @click="SearchData">
                      <i class="ri-equalizer-fill me-1 align-bottom"></i>
                      <i class="ri-equalizer-fill me-1 align-bottom"></i>
                      {{ $t('t-filters') }}
                    </button>
                  </div>
                
              </div>
            </div>
            <div class="p-5">
            </div>
           <div class="table-card mt-50">
                <div class="row thRow mt-50">
                    <div class="col-lg-3 col-md-12">
                        Cliente
                    </div>
                    <div class="col-lg-3 col-md-12">
                      Localizzazione
                    </div>
                    <div class="col-lg-1 col-md-12">
                      Data inizio
                    </div>
                    <div class="col-lg-1 col-md-12">
                      Data fine
                    </div>
                    <div class="col-lg-2 col-md-12">
                      Categoria progetto
                    </div>
                    
                    <div class="col-lg-1 col-md-12">
                      Stato lavorazione
                    </div>

                    <div class="col-lg-1 col-md-12 text-left">
                        Azioni
                    </div>
                </div>
              
              <div class="row list tRow form-check-all d-flex" v-for="(item, index) of projectsList" :key="index">
                    <div class="col-lg-3 col-md-12 ">
                        <div class="list-inline hstack gap-2 mb-0" v-if="item.customerDetails">
                        
                         <strong>{{ item.customerDetails.ragSoc }}</strong>
                        
                        </div>
                        <div class="list-inline hstack gap-2 mb-0" v-else>
                          ND
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-12 ">
                      <template v-if="item.worksite">
                        <template v-if="item.worksite.length>0">
                          {{ item.worksite[0].address }}
                          <br>
                          {{ item.worksite[0].city }} {{ item.worksite[0].zipCode }} 
                        </template>
                      </template>
                    </div>
                    <div class="col-lg-1 col-md-12 ">
                        <div class="list-inline hstack gap-2 mb-0" >
                          <template v-if="item.worksite">
                            <template v-if="item.worksite.length>0">
                              {{ convertDate(item.worksite[0].start_tmp) }}
                            </template>
                          </template>
                        </div>
                    </div>
                    <div class="col-lg-1 col-md-12 ">
                        <div class="list-inline hstack gap-2 mb-0" >
                          <template v-if="item.worksite">
                            <template v-if="item.worksite.length>0">
                              {{ convertDate(item.worksite[0].end_tmp) }}
                            </template>
                          </template>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-12 ">
                        <div class="list-inline hstack gap-2 mb-0" >
                          {{ splitStr(item.systemCategory,'_') }}<br>
                          {{ splitStr(item.systemSubCategory,'_') }}<br>
                        </div>
                    </div>
                    <div class="col-lg-1 col-md-12 ">
                        <div class="list-inline hstack gap-2 mb-0">
                          <template v-if="item">
                           
                            <template v-if="item.status=='init' || item.status==''">
                              Non Assegnato
                            </template>
                            <template v-else-if="item.status=='processing'">
                              <div class="avatar-sm flex-shrink-0">
                              <span class="avatar-title bg-info rounded-circle "  title="In lavorazione">
                                <i class="mdi mdi-timer-sand"></i>
                              </span>
                              </div>                              
                            </template>
                            <template v-else-if="item.status=='pending'">
                              <div class="avatar-sm flex-shrink-0">
                              <span class="avatar-title bg-warning rounded-circle "  title="In attesa">
                                <i class="bx bx-time-five"></i>
                              </span>
                              </div>
                            </template>   
                            <template v-else-if="item.status=='problem'">
                              <div class="avatar-sm flex-shrink-0">
                                <span class="avatar-title bg-danger rounded-circle" title="Problematica">
                                  <i class="bx bx-alarm-exclamation"></i>
                              </span>
                              </div>
                            </template>                         
                            <template v-else-if="item.status=='completed'">
                              <div class="avatar-sm flex-shrink-0">
                                <span class="avatar-title bg-success rounded-circle"  title="Completa">
                                  <i class="ri-checkbox-circle-line"></i>
                              </span>
                              </div>
                            </template>
                            <template v-else>
                              <div class="avatar-sm flex-shrink-0">
                                <span class="avatar-title bg-info rounded-circle "  title="In lavorazione">
                                <i class="mdi mdi-timer-sand"></i>
                              </span>
                              </div>
                            </template>    

                          </template>
                          <template v-else>
                            N.D.
                          </template>
                        </div>
                    </div>

                    <div class="col-lg-1 col-md-12 text-left">
                        <ul class="list-inline ">
                            <li class="list-inline-item edit" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Modifica">
                                <a href="javascript:void(0)" class="text-primary d-inline-block edit-item-btn" @click="editdata(item._id)">
                                  <i class="ri-pencil-fill fs-16"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
              
            </div>
           
           <!-- END TABLE -->

         

          </div>
          <!--end card-body-->
        </div>
        <!--end card-->
      </div>
      <!--end col-->
    </div>
    <!--end row-->
<!-- / Modulo tabella elenco progetti/cantieri -->      
</template>